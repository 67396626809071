/* all link states */
a,
a:link,
a:visited,
a:hover,
a:active {
  color: black !important;
  text-decoration: none !important;
  font-weight: normal !important;
}
